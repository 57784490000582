import React from "react";
import { Link } from "react-scroll";

import DrawerToggleButton from "../../components/SideDrawer/DrawerToggleButton";
import "./ToolbarContainer.css";

function ToolbarContainer(props) {
  return (
    <header className="toolbar">
      <nav className="toolbar__navigation">
        <div className="toolbar__toggle_button">
          <DrawerToggleButton click={props.drawerClickHandler} />
        </div>
        <div className="toolbar__logo">
          <img
            src={require(`../../assets/logo.png`)}
            className="logo_img"
            alt="..."
          />
        </div>
        <div className="toolbar_navigation_items">
          <ul>
            <li>
              <Link
                activeClass="active_link"
                to="main"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active_link"
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                activeClass="active_link"
                to="about"
                spy={true}
                smooth={true}
                offset={-22}
                duration={500}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active_link"
                to="team"
                spy={true}
                smooth={true}
                offset={-77}
                duration={500}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                activeClass="active_link"
                to="skills"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Skills
              </Link>
            </li>
          </ul>
        </div>
        <div className="buttonContainer">
          <Link
            activeClass="class_active"
            to="footer"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className="btn_main btn_small">Contact Us</button>
          </Link>
        </div>
      </nav>
    </header>
  );
}

export default ToolbarContainer;
