import React from "react";
import "./AboutContainer.css";

function AboutContainer() {
  return (
    <div id="about" className="about">
      <div className="inner_container about_wrapper">
        <h3 className="subtitle">About Us</h3>
        <h1 className="title">
          We are aware that creating client-oriented software takes a mixture of
          technical excellence and clear communication.
        </h1>
        <p>
          Every client is unique and we strive to deliver an individual,
          innovative and affordable software every time and to follow it through
          with an outstanding delivery which is both on time and within budget.
          We have over 15 years of development in this area and our previous
          clients, among others, include Conda, CofoundIT, Swissmade,
          Intellitech Global LLC, NetClick, SmartrSite, AdriaHost, Belgrade
          Business Network, YellowPages, Bank Intesa, ImpactHub, AMS insurance,
          Hypo Alpe Adria Bank, and more. We also pride ourselves on our
          after-sales client-care including our guarantees, staff-training and
          onsite and offsite support.
        </p>
      </div>
    </div>
  );
}

export default AboutContainer;
