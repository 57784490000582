import React from "react";
import "./App.css";

import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import AboutContainer from "./containers/AboutContainer/AboutContainer";
import SkillContainer from "./containers/SkillContainer/SkillContainer";
import PeopleContainer from "./containers/PeopleContainer/PeopleContainer";
import Partners from "./containers/Partners/Partners";
import MainContainer from "./containers/MainContainer/MainContainer";
import ToolbarContainer from "./containers/ToolbarContainer/ToolbarContainer";
import ServicesContainer from "./containers/ServicesContainer/ServicesContainer";
import FooterContainer from "./containers/FooterContainer/FooterContainer";

class App extends React.Component {
  state = {
    sideDrawerOpen: false,
    services: [
      {
        id: 1,
        source: "icon_development.svg",
        title: "Development & Devops",
        body: "We provide automation and monitoring, from integration and testing to deployment and infrastructure management.",
      },
      {
        id: 2,
        source: "icon_software.svg",
        title: "Software engineering",
        body: "We apply engineering principles to the development, maintaince, documentation and evaluation of your software.",
      },
      {
        id: 3,
        source: "icon_security.svg",
        title: "Security analysis",
        body: "We utilize manual and automated processes, scaning and evaluating code for security risks, vulnerabilities and flows.",
      },
      {
        id: 4,
        source: "icon_enterprise.svg",
        title: "Enterprise systems",
        body: "We design and develop enterprise applications, matching your organization needs, as integral part of your information system.",
      },
      {
        id: 5,
        source: "icon_api.svg",
        title: "Api design and development",
        body: "We create decoupled RESTful web services over HTTP application protocol, based on Open API specification.",
      },
      {
        id: 6,
        source: "icon_server.svg",
        title: "Serverless platforms",
        body: "We craft auto-scalable, pay-per execution platforms with low overhead, focusing on business values over infrastructure.",
      },
      {
        id: 7,
        source: "icon_jamstack.svg",
        title: "Jamstack web apps & spa",
        body: "We deliver modern Single Page Applications using JAMstack architecture based on client-side JavaScript.",
      },
      {
        id: 8,
        source: "icon_blockchain.svg",
        title: "Blockchain development",
        body: "We build DApp software development, smart contract audit and consulting services for Blockchain and DLT industries.",
      },
    ],
    people: [
      {
        id: 1,
        source: "picture_igor.jpg",
        sourceHover: "picture_igor_hover.jpg",
        name: "Igor",
        position: "Solution Architect",
        linkedin: "https://www.linkedin.com/in/igor-velickovic/",
        facebook: "https://www.facebook.com/igor.velickovic",
      },
      {
        id: 2,
        source: "picture_rade.jpg",
        sourceHover: "picture_rade_hover.jpg",
        name: "Rade",
        position: "Developer",
        linkedin: "https://www.linkedin.com/in/rade-radivojevic-50915612b/",
        facebook: "https://www.facebook.com/rade.radivojevic.71",
      },
      {
        id: 3,
        source: "picture_simic.jpg",
        sourceHover: "picture_simic_hover.jpg",
        name: "Simic",
        position: "Developer",
        linkedin: "https://www.linkedin.com",
        facebook: "https://www.facebook.com",
      },
      {
        id: 4,
        source: "picture_nebojsa.jpg",
        sourceHover: "picture_nebojsa_hover.jpg",
        name: "Nebojsa",
        position: "Developer",
        linkedin: "https://www.linkedin.com/in/nebojsa-nikolic-ab9193122/",
        facebook: "https://www.facebook.com/nikolic.nebojsa",
      },
      {
        id: 5,
        source: "picture_predrag.jpg",
        sourceHover: "picture_predrag_hover.jpg",
        name: "Predrag",
        position: "Devops",
        linkedin: "https://www.linkedin.com",
        facebook: "https://www.facebook.com",
      },
      {
        id: 6,
        source: "no_avatar.jpg",
        sourceHover: "no_avatar_hover.jpg",
        name: "Nikola",
        position: "Software Engineer",
        linkedin: "https://www.linkedin.com/in/nikola-tesovic/",
        facebook: "https://www.facebook.com",
      },
      {
        id: 7,
        source: "no_avatar.jpg",
        sourceHover: "no_avatar_hover.jpg",
        name: "Stefan",
        position: "Software Engineer",
        linkedin: "https://www.linkedin.com/in/sstefandev/",
        facebook: "https://www.facebook.com",
      },
      {
        id: 8,
        source: "picture_vladimir.jpg",
        sourceHover: "picture_vladimir_hover.jpg",
        name: "Vladimir",
        position: "Developer",
        linkedin: "https://www.linkedin.com/in/vladimir-stoimenov-980b5a172/",
        facebook: "https://www.facebook.com",
      },
      {
        id: 9,
        source: "no_avatar.jpg",
        sourceHover: "no_avatar_hover.jpg",
        name: "Vladana",
        position: "Project Manager",
        linkedin: "https://www.linkedin.com/in/vladanadonevski/",
        facebook: "https://www.facebook.com/vladana.donevski",
      },
    ],
    skills: [
      {
        id: 1,
        color: "bg-success",
        source: "icon_development.svg",
        title: "NodeJS",
        body: "250+ completed projects",
        percent: "91%",
      },
      {
        id: 2,
        color: "bg-danger",
        source: "icon_development.svg",
        title: "Angular",
        body: "180+ completed projects",
        percent: "93%",
      },
      {
        id: 3,
        color: "bg-primary",
        source: "icon_development.svg",
        title: "React",
        body: "211+ completed projects",
        percent: "85%",
      },
      {
        id: 4,
        color: "bg-info",
        source: "icon_development.svg",
        title: "Solidity",
        body: "90+ completed projects",
        percent: "89%",
      },
      {
        id: 5,
        color: "bg-success",
        source: "icon_development.svg",
        title: "PHP",
        body: "210+ completed projects",
        percent: "98%",
      },
      {
        id: 6,
        color: "bg-secondary",
        source: "icon_development.svg",
        title: "Serverless / AWS Lambda",
        body: "90+ completed projects",
        percent: "87%",
      },
      {
        id: 7,
        color: "bg-warning",
        source: "icon_development.svg",
        title: "JAMstack",
        body: "95+ completed projects",
        percent: "89%",
      },
      {
        id: 8,
        color: "bg-danger",
        source: "icon_development.svg",
        title: "GraphQL",
        body: "120+ completed projects",
        percent: "92%",
      },
    ],
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    return (
      <div style={{ height: "100%" }}>
        <ToolbarContainer drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer
          show={this.state.sideDrawerOpen}
          drawerClickHandler={this.drawerToggleClickHandler}
        />
        {backdrop}
        <MainContainer
          className="outer_container"
          style={{ marginTop: "64px" }}
        />
        <ServicesContainer services={this.state.services} />
        <AboutContainer />
        <PeopleContainer people={this.state.people} />
        <div className="outer_container">
          <hr />
        </div>
        <SkillContainer skills={this.state.skills} />
        <Partners />
        <FooterContainer />
      </div>
    );
  }
}

export default App;
