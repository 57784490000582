import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ServiceItem.css';

class ServiceItem extends Component {
  render() {
      const { source, title, body  } = this.props.service

    return (
      <React.Fragment>
          <div className="col">
              <div className="card service_card" >
                  <div>
                    <img 
                        src={require(`../../assets/${source}`)} 
                        className="card-img-top icon_img" 
                        alt="..."                         
                    />
                  </div>
                  <div className="card-body service_body">
                      <h5 className="card-title">{title}</h5>
                      <p className="card-text">{body}</p>
                  </div>
              </div>
          </div>
      </React.Fragment>
    )
  }
}

// Prop types
ServiceItem.propTypes = {
    service: PropTypes.object.isRequired
}

export default ServiceItem
