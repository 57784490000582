import React, { Component } from "react";
import "./Person.css";

class Person extends Component {
  state = {
    img: require(`../../assets/${this.props.person.source}`)
  };

  render() {
    const {
      source,
      sourceHover,
      name,
      position,
      linkedin,
      facebook
    } = this.props.person;

    return (
      <React.Fragment>
        <div className="col">
          <div className="card person_card">
            <img
              src={this.state.img}
              className="card-img-top"
              alt="..."
              onMouseEnter={() => {
                this.setState({
                  img: require(`../../assets/${sourceHover}`)
                });
              }}
              onMouseOut={() => {
                this.setState({
                  img: require(`../../assets/${source}`)
                });
              }}
            />
            <div className="card-body">
              <h5 className="card-title">{name}</h5>
              <p className="card-text">{position}</p>
              <a target="_blank" rel="noopener noreferrer" href={linkedin}>
                <i className="fab fa-linkedin-in social_icon mr-2" />
              </a>{" "}
              <a target="_blank" rel="noopener noreferrer" href={facebook}>
                <i className="fab fa-facebook-f social_icon" />
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Person;
