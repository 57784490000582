import React from 'react';
import './Partners.css';

function Partners() {
    return (
        <div className='partners'>
            <div className='inner_container'>
                <h3 className='people_subtitle subtitle' >Partners</h3>
                <h1 className='people_title title'>Our previous clients include</h1>

                <div id="carouselExampleInterval" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-interval="5000" >
                            <img src={require('../../assets/partners_logo1.png')} className="d-block w-100" alt="Partners logos" />
                        </div>                        
                        <div className="carousel-item" data-interval="5000" >
                            <img src={require('../../assets/partners_logo2.png')} className="d-block w-100" alt="Partners logos" />
                        </div>                        
                    </div>
                    <div className="carousel_buttons">
                        <a className="carousel-control-prev" href="#carouselExampleInterval" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleInterval" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>



                {/* <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center logo_container" style={{opacity: '0.3'}}>
                        <img className='partner_logo' src={require('../../assets/conda_logo.png')} alt="Partner Logo"/>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center logo_container" style={{opacity: '0.3'}}>
                        <img className='partner_logo' src={require('../../assets/yp_logo.png')} alt="Partner Logo"/>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center logo_container" style={{opacity: '0.3'}}>
                        <img className='partner_logo' src={require('../../assets/bankaintesa_logo.png')} alt="Partner Logo"/>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center logo_container" style={{opacity: '0.3'}}>
                        <img className='partner_logo' src={require('../../assets/swissmade_logo.png')} alt="Partner Logo"/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Partners
