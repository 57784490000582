import React, { Component } from "react";

import Skill from "../.././components/Skill/Skill";

class SkillContainer extends Component {
  render() {
    return (
      <div
        style={{ marginTop: "120px" }}
        id="skills"
        className="row outer_container"
      >
        <div className="col-lg-5 col-md-6 col-sm-12">
          <h3 className="subtitle">Skills</h3>
          <h1 className="title">Not to humble brag, but we do wonders with:</h1>
          <br />
          <img
            className="mb-5 p-3 d-none d-md-block"
            style={{ width: "90%" }}
            src={require(`../.././assets/skills.svg`)}
            alt="..."
          />
        </div>
        <div className="col-lg-7 col-md-6 col-sm-12">
          {this.props.skills.map(skill => (
            <Skill key={skill.id} skill={skill} />
          ))}
        </div>
      </div>
    );
  }
}

export default SkillContainer;
