import React, { Component } from 'react';
import Person from '../.././components/People/Person';

import './PeopleContainer.css';

class PeopleContainer extends Component {
  render() {
    return (
      <div id='team' className='inner_container people_section' >
        <h3 className='people_subtitle subtitle' >Crafters</h3>
        <h1 className='people_title title'>Who we are</h1>
        <div className="row">
            {
                this.props.people.map((person, index) => (
                    <Person key={person.id} person={person} index={index} />
                ))  
            }
        </div>
      </div>
    )
  }
}

export default PeopleContainer