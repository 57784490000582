import React, { Component } from 'react';
import ServiceItem from '../.././components/Services/ServiceItem';

import './ServicesContainer.css';

class ServicesContainer extends Component {
  render() {
    return (
      <div id='services' className='inner_container services' >
        <h3 className='subtitle services_subtitle' >Services</h3>
        <h1 className='title services_title'>Take a look at what we are best at</h1>
        <div className="row">
            {
                this.props.services.map((service, index) => (
                    <ServiceItem key={service.id} service={service} index={index} />
                ))  
            }
        </div>
      </div>
    )
  }
}

export default ServicesContainer
