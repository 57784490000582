import React, { Component } from "react";
import "./FooterContainer.css";

class FooterContainer extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: ""
  };

  handleNameChange = event => {
    this.setState({
      name: event.target.value
    });
  };
  handleEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  };
  handleSubjectChange = event => {
    this.setState({
      subject: event.target.value
    });
  };
  handleMessageChange = event => {
    this.setState({
      message: event.target.value
    });
  };
  handleSubmit = event => {
    console.log(
      `${this.state.name} ${this.state.email} ${this.state.subject} ${
        this.state.message
      }`
    );
    event.preventDefault();
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: ""
    });
  };

  render() {
    return (
      <div id="footer" className="outer_container footer">
        <h3 className="subtitle">Contact Us</h3>
        <h1 className="title">Start a project or just say hi!</h1>
        <div className="row footer_body">
          <div className="col-sm-12 col-md-6">
            <form className="form_fields" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  className="form-control"
                  aria-describedby="nameHelp"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  value={this.state.subject}
                  onChange={this.handleSubjectChange}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  value={this.state.message}
                  onChange={this.handleMessageChange}
                  placeholder="Message"
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  required
                />
              </div>
              <button type="submit" className="btn_main mb-5">
                Submit
              </button>
            </form>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="belgrade">
              <p className="location">
                <span>
                  <i className="fas fa-map-marker-alt" />
                </span>
                BELGRADE
              </p>
              <p className="address">27.Marta 35, 11060, Belgrade</p>
            </div>
          </div>
        </div>
        <div className="mt-5 ">
          <hr />
          <div>
            <p className="rights_reserved">© 2018 All rights reserved</p>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterContainer;
