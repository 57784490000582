import React from 'react';
import { Link } from "react-scroll";

import './SideDrawer.css';

const SideDrawer = props => {
    let drawerClasses = ['side-drawer'];
    if(props.show) {
        drawerClasses = ['side-drawer', 'open']
    }
  return (
    <nav className={ drawerClasses.join(' ') }>
        <ul>
        <li>
            <Link
                onClick={props.drawerClickHandler}
                activeClass="active_link"
                to="main"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                style={{cursor: 'pointer'}}
            >
                Home
            </Link>
          </li>
          <li>
              <Link
                  onClick={props.drawerClickHandler}
                  activeClass="active_link"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration= {500}
                  style={{cursor: 'pointer'}}
              >
                  Services
              </Link>
          </li>
          <li>
              <Link
                  onClick={props.drawerClickHandler}
                  activeClass="active_link"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-22}
                  duration= {500}
                  style={{cursor: 'pointer'}}
              >
                  About
              </Link>
          </li>
          <li>
              <Link
                  onClick={props.drawerClickHandler}
                  activeClass="active_link"
                  to="team"
                  spy={true}
                  smooth={true}
                  offset={-77}
                  duration= {500}
                  style={{cursor: 'pointer'}}
              >
                  Team
              </Link>
          </li>
          <li>
              <Link
                  onClick={props.drawerClickHandler}
                  activeClass="active_link"
                  to="skills"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration= {500}
                  style={{cursor: 'pointer'}}
              >
                  Skills
              </Link>
          </li>
          <li>
              <Link
                  onClick={props.drawerClickHandler}
                  activeClass="active_link"
                  to="footer"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration= {500}
                  style={{cursor: 'pointer'}}
              >
                  Contact
              </Link>
          </li>
        </ul>
    </nav>
  )
}

export default SideDrawer
