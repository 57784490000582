import React, { Component } from 'react';
import './Skill.css';

class Skill extends Component {
  render() {
    const { color, title, body, percent } = this.props.skill;

    return (
      <React.Fragment>
        <div className="card skill_card" style={{width: '100%'}}>
          <div className="card-body skill_card_body">
              <h5 className="skill_name">{title}</h5>
              <p className="card-text">{body}</p>
              <div className="progress" style={{width: '100%'}}>
                <div className={`progress-bar ${color}`} role="progressbar" style={{width: `${percent}`}} aria-valuenow="91" aria-valuemin="0" aria-valuemax="100">{percent}</div>
              </div>
          </div>
        </div>        
      </React.Fragment>
    )
  }
}

export default Skill
