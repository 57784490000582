import React from "react";
import "./MainContainer.css";

import { Link } from "react-scroll";

function MainContainer() {
  return (
    <div id="main" className="outer_container main">
      <div className="inner_container main_content row">
        <div className="main_text col-md-6 col-sm-12">
          <h1>
            We Build Awesome Serverless And Decentralized Platforms in the Cloud
          </h1>
          <p>Submit your project for free evaluation!</p>
          <Link
            activeClass="class_active"
            to="footer"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className="btn_main">Submit</button>
          </Link>
        </div>

        <div className="main_pic col-md-6">
          <img src={require("../../assets/main-pic.png")} alt="Main" />
        </div>
      </div>
      <div className="scroll-down">
        <i className="fas fa-angle-down" />
      </div>
    </div>
  );
}

export default MainContainer;
